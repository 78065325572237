<template>
  <div class="limiter">
    
    <div class="container-login100">
      <div class="text-right p-t-8 p-b-31" style="position: absolute; top: 20px; right: 20px;">
          <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn" style="background-color: black;"></div>
              <a href="/admin/view-payments" class="login100-form-btn" style="color: white;">
                View Payments
              </a>
            </div>
          </div>
        </div>
      <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
        
        
        <form class="login100-form validate-form" @submit.prevent="createPaymentLink">
          <span class="login100-form-title p-b-49">
            Payment Link Generator
          </span>
          
          
          
          <div class="wrap-input100 validate-input m-b-23" data-validate="Product name is required">
            <span class="label-input100">Product Name</span>
            <input v-model="productName" class="input100" type="text" name="product_name" placeholder="Type product name" required>
            <span class="focus-input100" data-symbol="&#xf1ac;"></span>
          </div>
          
          <div class="wrap-input100 validate-input m-b-23" data-validate="Amount is required">
            <span class="label-input100">Amount (in AUD)</span>
            <input v-model="amount" class="input100" type="number" step="0.01" name="amount" placeholder="Type amount" required>
            <span class="focus-input100" data-symbol="&#xf19a;"></span>
          </div>
          
          <div class="wrap-input100 validate-input m-b-23" data-validate="Account is required">
            <span class="label-input100">Select Account</span>
            <select v-model="selectedAccount" class="input100" name="account_name" style="width: 90%; margin-left: 40px; background-color: white;" required>
              <option v-for="account in activeAccounts" :key="account" :value="account">{{ account }}</option>
            </select>
            <span class="focus-input100" data-symbol="&#xf20b;"></span>
          </div>
          
          <!-- <div class="text-right p-t-8 p-b-31">
            <a href="#">
              <button type="submit" class="login100-form-btn">
                Create
              </button>
            </a>
          </div> -->
          
          <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button type="submit" class="login100-form-btn">
                Create
              </button>
            </div>
          </div>
        </form>
        
        <div v-if="notification" class="text-center p-t-50">
          <div class="txt1">PAYMENT LINK</div>
          <a :href="notification" class="txt2" target="_blank">{{ notification }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { API_BASE_URL } from '../config';

export default {
  data() {
    return {
      productName: '',
      amount: 0.00,
      notification: '',
      activeAccounts: [],
      selectedAccount: ''
    };
  },
  async created() {
    await this.fetchActiveAccounts();
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    return { store, router };
  },
  methods: {
    async fetchActiveAccounts() {
      try {
        const response = await axios.get(`${API_BASE_URL}/admin/active-api-accounts`, {
          headers: {
            Authorization: `Bearer ${this.store.state.token}`,
          },
        });
        this.activeAccounts = response.data.active_accounts;
      } catch (error) {
        console.error('Error fetching active accounts:', error);
        if (error.response && error.response.status === 401) {
          // Token expired, clear token and redirect to login
          this.store.commit('setToken', null);
          this.router.push('/login');
        }
      }
    },
    async createPaymentLink() {
      try {
        const token = this.store.state.token;
        const response = await axios.post(`${API_BASE_URL}/admin/create-payment-link`, {
          product_name: this.productName,
          amount: this.amount,
          account_name: this.selectedAccount,
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });
        
        console.log('Payment link created:', response.data);
        
        // Reset form values
        this.productName = '';
        this.amount = 0.00;
        
        // Display notification with the payment link
        this.notification = response.data.payment_link;
      } catch (error) {
        console.error('Error creating payment link:', error);
        if (error.response && error.response.status === 401) {
          // Token expired, clear token and redirect to login
          this.store.commit('setToken', null);
          this.router.push('/login');
        }
      }
    },
  },
};
</script>

<style>
body {
  font-family: ui-sans-serif, -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Helvetica, Apple Color Emoji, Arial, Segoe UI Emoji, Segoe UI Symbol;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  width: 98%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  background-color: #007aff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #005bb5;
}

.notification {
  width: 50%;
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f8ff;
  border: 1px solid #add8e6;
  border-radius: 5px;
}

.notification a {
  color: #007BFF;
  text-decoration: none;
  white-space: pre-wrap; /* CSS3 */    
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */    
  white-space: -o-pre-wrap; /* Opera 7 */    
  word-wrap: break-word; /* Internet Explorer 5.5+ */
};



.txt1 {
  /* font-family: Poppins-Regular; */
  font-size: 16px;
  color: black;
  line-height: 1.5;
  font-weight: bold;
}

.txt2 {
  /* font-family: Poppins-Regular; */
  font-size: 10px;
  color: #333333;
  line-height: 1.5;
  text-decoration: none;
  white-space: pre-wrap; /* CSS3 */    
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */    
  white-space: -o-pre-wrap; /* Opera 7 */    
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  text-transform: none;
}
</style>
