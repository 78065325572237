<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700&display=swap');
@import url('assets/vendor/bootstrap/css/bootstrap.min.css');
@import url('assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css');
@import url('assets/fonts/iconic/css/material-design-iconic-font.min.css');
@import url('assets/vendor/animate/animate.css');
@import url('assets/vendor/css-hamburgers/hamburgers.min.css');
@import url('assets/vendor/animsition/css/animsition.min.css');
@import url('assets/vendor/select2/select2.min.css');
@import url('assets/vendor/daterangepicker/daterangepicker.css');
@import url('assets/css/util.css');
@import url('assets/css/main.css');

.container-login100 {
  background-image: url('assets/images/bg-01.jpg');
}
</style>
