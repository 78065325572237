<template>
    <div class="limiter">
        <div class="container-login100">
            <div class="wrap-login100  p-l-55 p-r-55 p-t-65 p-b-54">
                <form class="login100-form validate-form" @submit.prevent="login">
                    <span class="login100-form-title p-b-49">
                        Login
                    </span>

                    <div class="wrap-input100 validate-input  m-b-23" data-validate="Valid email is required: ex@abc.xyz">
                        <span class="label-input100">Username</span>
                        <input v-model="username" class="input100" type="text" name="username" placeholder="Type your username" required>
                        <span class="focus-input100" data-symbol="&#xf206;"></span>
                    </div>

                    <div class="wrap-input100 validate-input" data-validate="Password is required">
                        <span class="label-input100">Password</span>
                        <input v-model="password" class="input100" type="password" name="password" placeholder="Type your password" required>
                        <span class="focus-input100" data-symbol="&#xf190;"></span>
                    </div>

                    <div class="text-right p-t-8 p-b-31">
						<a href="#">
							
						</a>
					</div>

                    <div class="container-login100-form-btn">
                        <div class="wrap-login100-form-btn">
                            <div class="login100-form-bgbtn"></div>
                            <button type="submit" class="login100-form-btn">
                                Login
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { API_BASE_URL } from '../config';

export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        return { store, router };
    },
    methods: {
        async login() {
            try {
                const response = await axios.post(`${API_BASE_URL}/login`, {
                    username: this.username,
                    password: this.password,
                });
                const token = response.data.access_token;
                localStorage.setItem('token', token);
                this.store.commit('setToken', token);
                this.router.push('/admin/create-payment');
            } catch (error) {
                console.error('Error logging in:', error);
            }
        },
    },
};
</script>