<template>
  <div class="limiter">
    <div class="container-login100">
      <div class="text-right p-t-8 p-b-31" style="position: absolute; top: 20px; right: 20px;">
        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn" style="background-color: black;"></div>
            <a href="/admin/create-payment" class="login100-form-btn" style="color: white;">
              Create Payment
            </a>
          </div>
        </div>
      </div>
      <div class="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
        <div class="login100-form validate-form">
          <span class="login100-form-title">
            Payment Links
          </span>
          <div class="statistics">
            <div class="stat-item">
              <span class="stat-title">Completed: </span>
              <span class="stat-value">{{ (sumCompleted / 100).toFixed(2) }} AUD</span>
            </div>
            <div class="stat-item">
              <span class="stat-title">Failed: </span>
              <span class="stat-value">{{ (sumFailed / 100).toFixed(2) }} AUD</span>
            </div>
            <div class="stat-item">
              <span class="stat-title">Expired: </span>
              <span class="stat-value">{{ (sumExpired / 100).toFixed(2) }} AUD</span>
            </div>
            <div class="stat-item">
              <span class="stat-title">Pending: </span>
              <span class="stat-value">{{ (sumPending / 100).toFixed(2) }} AUD</span>
            </div>
          </div>
          <table class="payment-links-table">
            <thead>
              <tr>
                <th>Account</th>
                <th>Product</th>
                <th>Amount (AUD)</th>
                <th>Status</th>
                <th>Link</th>
                <th>Copy Link</th>
                <th>Timestamp</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="link in paymentLinks" :key="link.id">
                <td>{{ link.account_name }}</td>
                <td>{{ link.product_name }}</td>
                <td>{{ (link.amount / 100).toFixed(2) }}</td>
                <td>{{ link.status }}</td>
                <td>
                  <a :href="link.payment_link" target="_blank" class="link">{{ link.payment_link }}</a>
                </td>
                <td>
                  <button @click="copyToClipboard(link.payment_link)" class="copy-button">Copy</button>
                </td>
                <td>{{ formatDate(link.created_at) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { API_BASE_URL } from '../config';

export default {
  data() {
    return {
      paymentLinks: [],
      sumCompleted: 0,
      sumFailed: 0,
      sumExpired: 0,
      sumPending: 0,
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    return { store, router, route };
  },
  async created() {
    const year = this.route.params.year;
    const month = this.route.params.month;

    if (!year || !month) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      this.router.push(`/admin/view-payments/${currentYear}/${currentMonth}`);
      return;
    }

    try {
      const token = this.store.state.token;
      const response = await axios.get(`${API_BASE_URL}/admin/payment-links/${year}/${month}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      this.paymentLinks = response.data.payment_links;
      this.sumCompleted = response.data.sum_completed;
      this.sumFailed = response.data.sum_failed;
      this.sumExpired = response.data.sum_expired;
      this.sumPending = response.data.sum_pending;
    } catch (error) {
      console.error('Error fetching payment links:', error);
      if (error.response && error.response.status === 401) {
        // Token expired or invalid, redirect to login
        this.store.commit('setToken', null);
        this.router.push('/login');
      }
    }
  },
  methods: {
    formatDate(dateString) {
      const options = {
        timeZone: 'Asia/Singapore',
        year: '2-digit',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', '').replace('AM', '').replace('PM', '');
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        alert('Link copied to clipboard');
      }).catch(err => {
        console.error('Failed to copy text: ', err);
      });
    }
  }
};
</script>

<style scoped>
.stat-title {
  font-weight: bold;
}

.statistics {
  text-align: center;
  padding-bottom: 40px;
}

.payment-links-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  white-space: nowrap; /* Prevent table content from wrapping */
}

.payment-links-table th, .payment-links-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

.payment-links-table th {
  background-color: #007aff;
  color: white;
  font-weight: bold;
}

.payment-links-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.payment-links-table tr:hover {
  background-color: #f1f1f1;
}

.link {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: #007BFF;
  text-decoration: none;
}

.copy-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #218838;
}

/* Ensure table is responsive */
.wrap-login100 {
  width: 1024px;
  overflow-x: auto;
  padding-left: 20px; /* Add padding for space on the left */
  padding-right: 20px; /* Add padding for space on the right */
}

.wrap-login100::-webkit-scrollbar {
  height: 8px;
}

.wrap-login100::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

/* Style adjustments for mobile view */
@media (max-width: 768px) {
  .payment-links-table th, .payment-links-table td {
    padding: 8px;
  }

  .wrap-login100 {
    padding: 20px;
    overflow-x: auto;
  }

  .login100-form-title {
    font-size: 18px;
  }

  .payment-links-table {
    display: block;
    width: auto; /* Allow table to scroll horizontally */
  }
}
</style>
