import { createRouter, createWebHistory } from 'vue-router';
import SuccessPage from '../components/SuccessPage.vue';
import CancelPage from '../components/CancelPage.vue';
import AdminCreatePayment from '../components/AdminCreatePayment.vue';
import AdminViewPayments from '../components/AdminViewPayments.vue';
import LoginPage from '../components/LoginPage.vue';

const routes = [
  { path: '/', component: LoginPage },
  { path: '/success', component: SuccessPage },
  { path: '/cancel', component: CancelPage },
  { path: '/admin/create-payment', component: AdminCreatePayment, meta: { requiresAuth: true } },
  { path: '/admin/view-payments', component: AdminViewPayments, meta: { requiresAuth: true } },
  { path: '/admin/view-payments/:year/:month', component: AdminViewPayments, meta: { requiresAuth: true } },
  { path: '/login', component: LoginPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    if (!token) {
      next({ path: '/login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

// Redirect to the current month and year if the /admin/view-payments route is visited without parameters
router.beforeEach((to, from, next) => {
  if (to.path === '/admin/view-payments') {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    next({ path: `/admin/view-payments/${currentYear}/${currentMonth}` });
  } else {
    next();
  }
});

export default router;
