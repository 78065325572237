<template>
    <div>
        <h1>Payment Successful!</h1>
        <p>Thank you for your purchase.</p>
    </div>
</template>

<script>
export default {
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        if (sessionId) {
            console.log('Session ID:', sessionId);
        }
    },
};
</script>
